function loadStyleSheet(src, id) {
  var styleSheet = document.createElement("link");
  styleSheet.rel = "stylesheet";
  styleSheet.type = "text/css";
  styleSheet.id = id;
  styleSheet.href = src;
  document.getElementsByTagName("head")[0].appendChild(styleSheet)
}

function loadJavaScript(src, callback) {
  var script = document.createElement("script");
  script.src = src;
  script.type = "text/javascript";
  script.onload = function () {
    if (callback) {
      callback();
    }
  }

  script.onerror = function () {
    console.error('script loading error');
  }

  document.getElementsByTagName("head")[0].appendChild(script);
}

function removeFrameStylesheet(dynamicStylesheetId, frameStylesheetId) {
  const timer = setInterval(removeStylesheet, 2);

  function removeStylesheet() {
    if (document.getElementById(dynamicStylesheetId) && document.getElementById(frameStylesheetId)) {
      document.getElementsByTagName("head")[0].removeChild(document.getElementById(frameStylesheetId));

      // load settings css after the dynamic css
      const settingsCssElement = document.getElementById('settings-css');
      const settingsCss = settingsCssElement.innerHTML;
      settingsCssElement.remove();
      const newSettingsCssElement = document.createElement('style');
      newSettingsCssElement.innerHTML = settingsCss;
      document.getElementsByTagName("head")[0].appendChild(newSettingsCssElement);

      clearInterval(timer);
    }
  }

  setTimeout(function () {
    if (document.getElementById(frameStylesheetId)) {
      clearInterval(timer);
    }
  }, 10000);
}

function loadAssets(pageScript) {
  const loadAssetsDataElement = document.getElementById('load-assets-data');
  const cdnUrl = loadAssetsDataElement.dataset.cdnUrl;
  const xObj = new XMLHttpRequest();
  const manifestUrl = cdnUrl + 'rev-manifest.json';
  const dynamicCssId = 'dynamic_css';
  const frameCssId = 'frame-css';
  xObj.open("GET", manifestUrl);
  xObj.send();

  xObj.onreadystatechange = () => {
    if (xObj.readyState === 4) {
      if (xObj.status === 200) {
        const response = JSON.parse(xObj.responseText);
        const frameCssUrl = document.getElementById('frame-css').href;
        const dynamicCssUrl = cdnUrl + response['min/css/styles.css'];

        if (frameCssUrl !== dynamicCssUrl) {
          loadStyleSheet(dynamicCssUrl, dynamicCssId);
          removeFrameStylesheet(dynamicCssId, frameCssId);
        }

        loadJavaScript(cdnUrl + response['min/js/vendor.js'], () => {
          loadJavaScript(cdnUrl + response['min/js/scripts.js']);
          if (pageScript) {
            loadJavaScript(cdnUrl + response[pageScript]);
          }
        });
      }
    }
  }
}

var pageScript = '';

if (window.location.pathname === '/' || window.location.pathname.indexOf('/site-preview') !== -1) {
  pageScript = 'min/js/jobs_list.js';
} else if (window.location.pathname.indexOf('/job/') !== -1) {
  pageScript = 'min/js/job_details.js';
}

loadAssets(pageScript);
